<template>
  <div
    class="d-flex flex-column flex-grow-1"
    v-if="document.id"
  >
    <document-toolbar
      :tab="tab"
      :files.sync="document.attachments"
      :revert="refreshDocument"
      :save="saveDocument"
      :readonly-file-manager="!document.canUpdate"
      :hasComment="!!(document.description || '').length || !!(document.custom || '').length"
      show-file-manager
      :show-save="showButtons"
      :show-cancel="showButtons"
      comment
      v-on="$listeners"
      :links.sync="document.links"
      :show-link-manager="!documentIsNew"
      :labels.sync="document.labels"
      :show-labels="!documentIsNew"
      @close-pop-up="refreshCache()"
      @update-links="refreshCache()"
    >
      <v-col
        cols="auto"
        class="ml-3"
        v-if="canShowStatusSelector"
      >
        <v-select
          v-if="!documentIsNew"
          class="mx-1"
          solo
          hide-details
          :items="availableStatuses"
          v-model="promiseStatus"
          :disabled="!document.canUpdate || !isPristine && initialPromiseStatus === promiseStatus"
          item-text="name"
          item-value="id"
          :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true}"
        />
      </v-col>
      <v-col
        cols="auto"
        class="ml-3"
      >
        <promise-status
          v-if="cache && cache.doc && cache.doc.status"
          :status="displayablePromiseStatus"
          :outlinedChip="true"
        />
      </v-col>
      <v-col
        cols="auto"
        class="ml-3"
      >
        <account-ref
          v-if="document.accountId !== $route.params.id"
          :icon-props="{small: true}"
          :id="document.accountId"
          class="clickable secondary--text"
          @click.native.stop="$router.push('/accounts/' + document.accountId).catch(() => {})"
        />
      </v-col>
    </document-toolbar>
    <document-in-tab>
      <v-form
        v-model="form"
        ref="form"
        :disabled="promiseStatus !== initialPromiseStatus"
      >
        <grid item-min-width="300px">
          <comment
            :text.sync="document.description"
            :disabled="promiseStatus !== initialPromiseStatus || !document.canUpdate"
            :showComment="tab.showComment"
            class="mb-4"
          />
          <comment
            :text.sync="document.custom"
            :disabled="promiseStatus !== initialPromiseStatus || !document.canUpdate"
            :showComment="tab.showComment"
            :label="$t('t.AdditionalInformation')"
            class="mb-4"
          />
        </grid>
        <v-card class="mb-4">

          <v-card-title>
            <div
              class="minimum-width text-h5"
              v-if="document.invoices.length"
            >
              <span class="primary--text font-weight-bold">{{document.invoices.length}}</span>
              <span v-if="document.invoices.length == 1"> {{$t('t.InvoiceSelected')}} </span>
              <span v-else> {{$t('t.InvoicesSelected')}} </span>
              <span :class="[ 'font-weight-bold', document.amount > 0 ?'primary--text' : 'error--text']">{{formatCurrency(document.amount, document.currency)}}</span>
            </div>
            <div
              class="minimum-width text-h5"
              v-else-if="promiseType === 'onInvoice'"
            >
              {{$t('t.Promise')}}
            </div>
            <div
              class="minimum-width text-h5"
              v-else
            >
              {{$t('t.PromiseOnAccount')}}
            </div>
          </v-card-title>
          <v-card-text>
            <promises-amount
              :promise.sync="document"
              :promiseType="promiseType"
              @update:promise="recomputeInstallments"
            />
          </v-card-text>
        </v-card>
        <grid item-min-width="300px">
          <v-card>
            <v-card-title>
              {{$t('t.Installment')}}
            </v-card-title>
            <v-card-text>
              <promises-infos
                :promise.sync="document"
                @update:promise="validateForm"
              />
              <v-divider />
              <promises-installments
                :promise.sync="document"
                @update:promise="validateForm"
                ref="installments"
              />
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-title>
              {{$t('t.Notifications')}}
            </v-card-title>
            <v-card-text>
              <promises-notification
                :promise.sync="document"
                @update:promise="validateForm"
              />
            </v-card-text>
          </v-card>
        </grid>
      </v-form>
    </document-in-tab>
  </div>
</template>

<script>
import DocumentEditor from '@/mixins/document-editor'
import Decimal from 'decimal.js'

export default {
  mixins: [DocumentEditor],
  components: {
    Grid: () => import('@/components/grid'),
    AccountRef: () => import('@/components/documents-ref/account-ref'),
    Comment: () => import('@/components/comment'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    PromiseStatus: () => import('@/components/promise-status'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    PromisesAmount: () => import('./promises-amount'),
    PromisesInstallments: () => import('./promises-installments'),
    PromisesInfos: () => import('./promises-infos'),
    PromisesNotification: () => import('./promises-notification')
  },
  created () {
    Object.assign(this.tab, { cancel: this.cancel })
  },
  data () {
    return {
      promiseType: null,
      dialog: false,
      required: [
        v => !!v || this.$t('t.IsRequired')
      ]
    }
  },
  computed: {
    availableStatuses () {
      const status = [
        {
          id: 0,
          name: this.$t('t.Open')
        },
        {
          id: 1,
          name: this.$t('t.Held')
        },
        {
          id: 2,
          name: this.$t('t.Broken')
        }
      ]
      if (this.document?.installments?.every(i => !i.paidDate)) {
        status.push({
          id: 3,
          name: this.$t('t.Delete')
        })
      }
      return status
    },
    canShowStatusSelector () {
      return !this.cache?.doc?.status?.id
    },
    displayablePromiseStatus () {
      return this.cache?.doc?.status
    },
    cacheType () {
      return DocumentEditor.CacheType.PromiseDetail
    },
    id () {
      return this.tab.documentId
    },
    form: {
      get () {
        return false
      },
      set (v) {
        this.$emit('document-can-save', { tab: this.tab, value: Boolean(this.document.canUpdate && v && this.document.isTotalAmountFromInstallmentsValide && this.document.amount !== 0) })
      }
    },
    initialPromiseStatus () {
      return this.cache?.doc?.status?.id
    },
    promiseStatus: {
      get () {
        return this.document.status?.id
      },
      set (id) {
        this.document = Object.assign({}, this.document, { status: Object.assign({}, this.document.status, { id }) })
      }
    },
    showButtons () {
      return !this.cache?.doc?.status?.id || !this.document?.status?.id
    },
    toleranceSummary () {
      let amount
      let days = null
      if (this.document.toleranceAmount) {
        amount = this.document.isToleranceAmountPercentage ? this.document.toleranceAmount + '%' : this.document.toleranceAmount + this.currencySymbol(this.document.currency)
      }
      if (this.promise.toleranceDays) {
        days = this.$tc('t.DaysX', this.document.toleranceDays)
      }

      if (amount && days) {
        return amount + ' ' + this.$t('t.And') + ' ' + days
      } else {
        return amount || days
      }
    },
    installmentsCount () {
      return this.document.installments?.length
    },
    invoiceReadOnly () {
      return !this.$store.getters.currentUserHasPermission('InvoiceDetails')
    }
  },
  methods: {
    async refreshDocumentHook () {
      if (this.documentIsNew) {
        this.document = Object.assign({}, this.document, {
          id: this.tab.documentId,
          accountId: this.$route.params.id,
          installments: [{
            id: this.uuid(),
            targetDate: null,
            amount: null
          }],
          invoices: [],
          toleranceDays: 0,
          toleranceAmount: 0,
          amount: null,
          attachments: [],
          createBy: this.$store.getters.currentUser.id,
          isToleranceAmountPercentage: false,
          confirmations: [],
          reminders: [],
          isTotalAmountFromInstallmentsValide: true,
          sameAmountOnAll: true,
          canUpdate: true,
          frequency: 'SpecificDates',
          daysBeforeDueDate: 1,
          currency: this.$store.getters.currentUser.currency,
          consolidated: true,
          custom: null,
          description: null
        })
        this.promiseType = (await this.$http().get('/core/v6/promise/accountPromiseType/' + this.$route.params.id)).data.promiseType
        if (Array.isArray(this.tab.invoices) && this.promiseType !== 'onAccount') {
          this.document.invoices = this.tab.invoices.map(invoice => Object.assign(invoice, { amountPromised: invoice.promiseAmountAllowed, errorMessage: '' }))
          this.document.amount = this.document.invoices.reduce((acc, i) => acc.plus(new Decimal(i.amountPromised)), new Decimal(0)).toNumber()
          this.document.currency = this.document.invoices[0].currency
          this.recomputeInstallments()
        }
      } else {
        // Prevent doing anything if the cache isn't loaded
        if (!this.document?.id) { return }
        this.$emit('document-title-change', { tab: this.tab, value: `${this.tab.typeName} ${this.document.number}` })
        this.document = Object.assign({}, this.document, { isTotalAmountFromInstallmentsValide: true })
        if (this.$route.params.id) {
          this.promiseType = (await this.$http().get('/core/v6/promise/accountPromiseType/' + this.$route.params.id)).data.promiseType
        }

        if (this.document.description?.length || this.document.custom?.length) {
          this.$emit('document-comment', { tab: this.tab, value: true })
        }
      }

      await this.$waitFor(() => this.$refs?.form)
        .then(form => setTimeout(() => {
          form?.validate()
          this.$emit('document-ready', this.tab)
        }, 500))
        .catch(e => {
          // Do not throw if the cache is falsy, that mean the user as already close the document
          if (this.cache) {
            throw e
          }
        })
    },
    cancel () {
      this.$removeFromKeepAliveCache()
      this.$destroy()
    },
    async save () {
      if (this.initialPromiseStatus === this.promiseStatus) {
        if (this.documentIsNew) {
          return await this.$http().post('/core/v6/promise', this.promiseToJSON())
        } else {
          return await this.$http().put(`/core/v6/promise/${this.document.id}`, this.promiseToJSON())
        }
      } else {
        return await this.$http().put('/core/v6/promise/' + this.document.id, { status: this.promiseStatus })
      }
    },
    recomputeInstallments () {
      this.$nextTick(() => {
        this.$refs.installments?.splitAmountOnInstallments()
        this.validateForm()
      })
    },
    promiseToJSON () {
      // Remap invoices
      const formatedInvoices = this.document.invoices.map(i => ({
        invoiceId: i.id,
        promiseId: this.id,
        amount: i.amountPromised
      }))

      const formatedAttachments = this.document.attachments?.map(a => a.id)

      return {
        id: this.document.id,
        accountId: this.document.accountId,
        installments: this.document.installments,
        invoices: formatedInvoices,
        toleranceDays: this.document.toleranceDays,
        toleranceAmount: this.document.toleranceAmount,
        amount: this.document.amount,
        attachments: formatedAttachments,
        isToleranceAmountPercentage: this.document.isToleranceAmountPercentage,
        confirmations: this.document.confirmations.map(c => ({ userId: c.userId || c.id })),
        reminders: this.document.reminders.map(c => ({ userId: c.userId || c.id })),
        isTotalAmountFromInstallmentsValide: true,
        frequency: this.document.frequency,
        daysBeforeDueDate: this.document.daysBeforeDueDate,
        currency: this.document.currency,
        custom: this.document.custom,
        description: this.document.description
      }
    },
    validateForm () {
      this.$nextTick(this.$refs?.form?.validate)
    }
  },
  props: {
    tab: Object
  }
}
</script>
